<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 일상점검 결과 목록 -->
          <c-table
            ref="table"
            title= "일상점검 결과 목록"
            :merge="grid.merge"
            :columns="grid.columns"
            :data="grid.data"
            :gridHeight="grid.height"
            selection="multiple"
            rowKey="equipmentCd"
            :editable="!disabled"
            :columnSetting="false"
            :usePaging="false"
            @headLinkClick="headLinkClick"
          >
            <template slot="prefixContent">
              <font v-if="!data.lineId && !data.checkDate" color="#C10015">
                {{$language('※ 라인과 점검년월을 선택하세요.')}}
              </font>
              <font style="font-size:0.8em;font-weight:300;">
                <q-icon name="radio_button_unchecked" class="text-black"/> :  {{$language('정상')}} &nbsp;&nbsp;
                <q-icon name="close" class="text-black"/> :  {{$language('이상')}} &nbsp;&nbsp;
                <q-icon name="circle" class="text-black"/> :  {{$language('청소')}} &nbsp;&nbsp;
                V : {{$language('교환')}} &nbsp;&nbsp;
                <q-icon name="check_box_outline_blank" class="text-black"/> : {{$language('마모')}} &nbsp;&nbsp;
                △ : {{$language('Oil 누유')}} &nbsp;&nbsp;
                ▲ : {{$language('점검필요')}} &nbsp;&nbsp;
                <q-icon name="radio_button_checked" class="text-black"/> : {{$language('비가동')}} &nbsp;&nbsp;
              </font>
            </template>
            <!-- 버튼 영역 -->
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name === 'cycle1'">
                <span v-if="props.row['cycle1'] === 'Y'">
                  {{ '●' }}
                </span>
              </template>
              <template v-else-if="col.name === 'cycle2'">
                <span v-if="props.row['cycle2'] === 'Y'">
                  {{ '●' }}
                </span>
              </template>
              <template v-else-if="col.name === 'cycle3'">
                <span v-if="props.row['cycle3'] === 'Y'">
                  {{ '●' }}
                </span>
              </template>
              <template v-else>
                <span v-if="props.row[col.name]">
                  {{props.row[col.name]}}
                </span>
              </template>
            </template>
          </c-table>
        </div>  
      </div>
      <c-dialog :param="popupOptions" :returnData="{ col1: data.dailyCheckId }">></c-dialog>
    </q-form>
  </div>
</template>

<script>

import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-daily-result',

  props: {
    data: {
      type: Object,
      default: () => ({
        dailyCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        lineId: '',  // 라인일련번호
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkDate: '',  // 점검월
        dailyName: '',  // 점검명
        evaluationCompleteFlag: 'N',  // 결재완료 후 평가완료 처리
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        utilityFlag: 'N',
        dailyResultModelList: [], // sheet
        dailyImproveModelList: [], 
      }),
    },
    grid: {
      merge: [
        { index: 0, colName: 'equipmentCd' },
      ],
      height: 'auto',
      columns: [],
      data: [],
    },
  },
  data() {
    return {
      year: '',
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      updateMode: false,
      editable: true,
      saveUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isSave: false,
      isComplete: false,
      isApproval: false,
      mappingType: 'POST',
      resultItemDetail: '',
      currentMonth: '',
      lunarHolidays: [],
      today: '',
      lineCd: '',
      checkboxItems: [
        // { code: null, codeName: ''},
        { code: 'CHK001', codeName: 'o'},
        { code: 'CHK002', codeName: 'X'},
        { code: 'CHK003', codeName: '●'},
        { code: 'CHK004', codeName: 'V'},
        { code: 'CHK005', codeName: '□'},
        { code: 'CHK006', codeName: '△'},
        { code: 'CHK007', codeName: '▲'},
        { code: 'CHK008', codeName: '⊙'},
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.data.checkStatusCd === 'MCSC000015' || Boolean(this.data.sysApprovalRequestId)
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;

      this.today= this.$comm.getToday().slice(-2, this.$comm.getToday().length);
      this.currentMonth= this.$comm.getToday().slice(-5, this.$comm.getToday().length -3);
      if (this.today.substring(this.today, 0) === '0') {
        this.today = this.today.substring(this.today, 0)
      }
      // url setting
      this.saveUrl = transactionConfig.sop.min.equipment.plan.daily.insert.url;
      this.insertUrl = transactionConfig.sop.min.equipment.plan.daily.insert.url;
      this.updateUrl = transactionConfig.sop.min.equipment.plan.daily.update.url;
      this.deleteUrl = transactionConfig.sop.min.equipment.plan.daily.delete.url;
      this.completeUrl = transactionConfig.sop.min.equipment.plan.daily.complete.url;
      this.resultItemDetail = selectConfig.sop.min.equipment.result.daily.newItem.url;
      this.lunarList = selectConfig.sop.min.equipment.result.lunar.url; // 음력 공휴일구하기
      // code setting
      // list setting
    },
    /* eslint-disable no-unused-vars */
    headLinkClick(props, col, index) {
      let selectData = this.$refs['table'].selected;
      let realData = [];
      this.$_.forEach(selectData, _item => {
        this.$_.forEach(this.$_.filter(this.grid.data, {equipmentCd : _item.equipmentCd}), __item => {
          if (this.$_.findIndex(realData, {dailyCheckResultId: __item.dailyCheckResultId}) === -1) {
            realData.push(__item)
          } 
        })
      })
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '점검할 설비/장비를 체크하세요.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.popupOptions.param = {
          col: col,
          equipmentList: realData,
          data: this.data,
        };
        this.popupOptions.title = '일상점검 결과 상세'; 
        this.popupOptions.target = () =>
          import(`${"./equipmentDailyChecking.vue"}`);
        this.popupOptions.visible = true;
        this.popupOptions.isFull = false;
        this.popupOptions.width = '95%';
        this.popupOptions.closeCallback = this.closeResultPopup;
      }
    },
    closeResultPopup(returnData) {
      this.$refs['table'].selected = []
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (returnData.col1) {
        this.data.dailyCheckId = returnData.col1;
        this.$emit('setKey', returnData.col1)
      } else {
        this.$emit('getDetail')
      }
    },
    datachange2(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
  }
};
</script>
